<template>
    <button :type="type" 
        class="group flex flex-row items-center transition-all whitespace-nowrap duration-500"
        v-bind:class="{
            'py-2.5 px-4 w-full text-center justify-center': showAs === 'full',
            'py-2.5 px-4 w-full text-center justify-start': showAs === 'full-left',
            'py-2.5 px-4': showAs === 'normal',
            'p-2.5 md:p-3': showAs === 'round' || showAs === 'small',
            'rounded': (showAs === 'full' || showAs === 'normal' || showAs === 'full-left') && !(isSquare && !rounded),
            'rounded-full': showAs === 'round' && !(isSquare && !rounded),
            'rounded-2xl': showAs === 'small' && !(isSquare && !rounded),

            'shadow-xl hover:shadow-2xl': !noShadow && color === 'primary',
            'shadow hover:shadow-xl': !noShadow && color !== 'primary',

            'bg-primary hover:bg-blue-700 text-white': color === 'primary',
            'bg-secondary dark:bg-gray-700 hover:bg-cyan-500 text-white': color === 'secondary',
            'bg-transparent border-2 border-primary hover:bg-primary text-current hover:text-white': color === 'empty',
            'border-2 hover:bg-black hover:border-black hover:text-white focus:outline-none focus:bg-primary focus:border-primary focus:text-white': color === 'white',
            'bg-white border-white text-current dark:bg-slate-700 dark:border-gray-700 dark:text-white': color === 'white' && !toggled,
            'bg-red-600 border-red-700 text-white': color === 'danger' && !toggled,

            'bg-primary border-primary text-white' : toggled,

            'gap-3': showAs !== 'small' && showAs !== 'round' && (loading || icon !== ''),

            'font-bold' : !noBold,


            'visible opacity-100' : active,
            'invisible opacity-25' : !active,
        }">
        <div v-bind:class="{
            'mt-0.5': showAs !== 'small' && showAs !== 'round' && (loading || icon !== ''),
            'rounded-full bg-blue-100 p-3 shadow text-black group-hover:text-black  ' : grayIcon,
            }">
            <ThemeIconsLoading v-if="loading" />
            <slot name="icon" v-else>
                <ElementsIcon :icon="icon" noTransition v-if="icon !== ''" />
            </slot>
        </div>
        <slot v-if="!(showAs === 'full' && loading)" />
        <LazyElementsHelpBubble v-if="path" :path="'button.'+path" class="ml-2" />
    </button>
</template>
<script setup>
defineProps({
    type: { type: String, default: 'button' },
    showAs: { type: String, default: 'normal'},
    color: { type: String, default: 'primary'},
    icon: { type: String, default: ''},
    loading: { type: Boolean, default: false },
    isSquare: { type: Boolean, default: false },
    toggled: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    grayIcon: { type: Boolean, default: false },
    noShadow: { type: Boolean, default: false },
    noBold: { type: Boolean, default: false },

    path: { type: String, default: null },
});
const active = ref(false)
onMounted(function(){
    setTimeout(function(){ active.value = true }, 10)
})
onBeforeUnmount(() => active.value = false )
</script>